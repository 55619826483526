@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jura&display=swap");

* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100%;
}

body {
  -webkit-font-smoothing: antialiased !important;
}

body,
input,
button {
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  font-size: 12pt;
}

::-webkit-scrollbar {
  position: absolute;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #2fd3c7;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 30px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.float:hover {
  color: #fff !important;
}
